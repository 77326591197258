import { useRouter } from "next/router";
import Button from "../feature/elements/button";
import { FaPlayCircle } from "react-icons/fa";
import { useRef, useState } from "react";

const BENEFITS = [
  "Unlimited writing parties",
  "Unlimited documents",
  "Unlimited participants",
  "Sharing",
  "Editing",
  "Dark mode",
  "Shortcuts",
  "Focus mode",
  "And all the rest",
];

const FAQS = [
  {
    question: "What is a writing party?",
    answer:
      "A writing party is a group of people writing together in real-time. Everybody writes their own text and the creator of the party can see all of them.",
  },
  {
    question: "How many people can join a writing party?",
    answer:
      "As many as you want! But be careful, too many people can be chaotic.",
  },
  {
    question: "How many documents can I create?",
    answer: "As many as you want! There is no limit.",
  },
  {
    question: "Can I share my documents?",
    answer:
      "Yes, you can share your documents with others. You can also invite them to write together.",
  },
  {
    question: "Is it really free?",
    answer:
      "Yes, it is free. But if you want to support the development you can donate.",
  },
  {
    question: "Can I use Koala Write for my online lessons?",
    answer:
      "Yes, you can. You can create a writing party and invite your students to write together. You can also see their writing in real-time.",
  },
  {
    question: "How do I report a bug?",
    answer:
      "You can report a bug by clicking the help button in the top right menu. You must be logged in to report a bug.",
  },
  {
    question: "How do I contact you?",
    answer: "You can contact me via email at david@vangucht.dev",
  },
];

export default function Home({ Props }) {
  const router = useRouter();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = useState(false);

  const handlePlayButton = () => {
    if (playing) return;
    if (videoRef.current) {
      videoRef.current.play();
      setPlaying(true);
    }
  };

  return (
    <>
      <div className="h-[50svh] flex flex-col justify-center">
        <div className="text-center text-secondaryDark dark:text-gray-200">
          <h1 className="text-5xl md:text-6xl mt-40 font-bold">
            Welcome to Koala Write!
          </h1>
          <h2 className="my-3">
            A collaborative writing platform for online lessons 🐾
          </h2>
        </div>
        <div className="w-full md:w-1/2 mx-auto mt-6">
          <Button onClick={() => router.push("/auth/signin")}>
            <p className="text-lg">Start writing</p>
          </Button>
          <p className="text-sm font-bold text-secondaryDark dark:text-gray-200 text-center mt-3">
            {`It's free! 🎉 `}
          </p>
        </div>
      </div>
      <div className="relative mt-32 sm:mt-0  drop-shadow-2xl">
        {!playing && (
          <button
            onClick={handlePlayButton}
            className="absolute w-full h-full group z-20 flex justify-center items-center"
          >
            <FaPlayCircle className="h-14 w-14 md:h-20 md:w-20 duration-200 group-hover:scale-110  text-secondary " />
          </button>
        )}
        <video
          ref={videoRef}
          playsInline
          className="w-full rounded-lg  "
          controls
          onPause={() => setPlaying(false)}
        >
          <source
            src="https://bucket.koalawrite.com/landing-video.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <h2 className="text-secondaryDark py-12 mt-32  dark:text-gray-200 text-4xl font-bold text-center">
        Features for writers 📝
        <br />
        <span className="text-sm">Yes, you are a writer too!</span>
      </h2>

      <div className="flex flex-col gap-24 ">
        <div className="flex flex-col-reverse md:flex-row gap-6 items-center">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="w-full md:w-1/2 drop-shadow-lg rounded-lg border-secondary dark:border-secondaryDark border-4"
          >
            <source
              src="https://bucket.koalawrite.com/write-together-2.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="w-full flex flex-col gap-4 text-center">
            <h3 className="text-secondaryDark dark:text-gray-200 text-3xl font-semibold">
              Writing parties! 🎉
            </h3>
            <p className="text-secondaryDark dark:text-gray-200">
              Write together with your friends, family, or students.
              <br /> See their writing live, give feedback via stream and have
              fun!
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row-reverse gap-6 items-center">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="w-full md:w-1/2 drop-shadow-lg rounded-lg border-secondary dark:border-secondaryDark border-4"
          >
            <source
              src="https://bucket.koalawrite.com/focus-mode-2.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="w-full flex flex-col gap-4 text-center">
            <h3 className="text-secondaryDark dark:text-gray-200 text-3xl font-semibold">
              Focus mode 🧘‍♀️
            </h3>
            <p className="text-secondaryDark dark:text-gray-200">
              Turn on focus mode and write without any distractions. <br />
              Just you and your thoughts. 🌌
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-6 items-center">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="w-full md:w-1/2 drop-shadow-lg rounded-lg border-secondary dark:border-secondaryDark border-4"
          >
            <source
              src="https://bucket.koalawrite.com/edit-with-shortcuts-2.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="flex flex-col gap-4 text-center w-full">
            <h3 className="text-secondaryDark dark:text-gray-200 text-3xl font-semibold">
              Shortcuts 🚀
            </h3>
            <p className="text-secondaryDark dark:text-gray-200">
              Use shortcuts to format your text faster. <br />
              <strong>Bold,</strong> <i>italic,</i> <code>code,</code> you name
              it!
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row-reverse gap-6 items-center">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="w-full md:w-1/2 drop-shadow-lg rounded-lg border-secondary dark:border-secondaryDark border-4"
          >
            <source
              src="https://bucket.koalawrite.com/edit-like-a-pro-2.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="flex flex-col gap-4 text-center w-full">
            <h3 className="text-secondaryDark dark:text-gray-200 text-3xl font-semibold">
              Full text editor 🖋
            </h3>
            <p className="text-secondaryDark dark:text-gray-200">
              Write with a full-featured text editor. <br />
              Format your text the way you want it.
            </p>
          </div>
        </div>
        {/* dark-mode-2.mp4 */}
        <div className="flex flex-col-reverse md:flex-row gap-6 items-center">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="w-full md:w-1/2 drop-shadow-lg rounded-lg border-secondary dark:border-secondaryDark border-4"
          >
            <source
              src="https://bucket.koalawrite.com/dark-mode-2.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="w-full flex flex-col gap-4 text-center">
            <h3 className="text-secondaryDark dark:text-gray-200 text-3xl font-semibold">
              Dark mode 🌕
            </h3>
            <p className="text-secondaryDark dark:text-gray-200">
              Join the dark side!
            </p>
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row-reverse gap-6 items-center">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="w-full md:w-1/2 drop-shadow-lg rounded-lg border-secondary dark:border-secondaryDark border-4"
          >
            <source
              src="https://bucket.koalawrite.com/koala-write-share-2.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="w-full flex flex-col gap-4 text-center">
            <h3 className="text-secondaryDark dark:text-gray-200 text-3xl font-semibold">
              Share with others 📄
            </h3>
            <p className="text-secondaryDark dark:text-gray-200">
              Invite your friends to write together or share your creations
              easily.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-secondaryDark py-12 mt-12   dark:text-gray-200 text-4xl font-bold text-center">
            Pricing 💰
            <br />
            <span className="text-sm">Nah, just kinding. It&apos;s free!</span>
          </h2>
          <div className="rounded-3xl w-full max-w-lg mx-auto dark:bg-gray-800 p-10 ring-2 ring-secondary dark:ring-gray-200">
            <div className="flex items-center justify-between gap-x-4">
              <h3
                id="tier-startup"
                className="text-lg font-semibold leading-8 text-secondary dark:text-gray-200"
              >
                Writer
              </h3>
              <p
                className="rounded-full bg-secondary/10 px-2.5 py-1 text-xs font-semibold leading-5 text-secondary
            dark:bg-gray-200 dark:text-gray-800 dark:bg-opacity-50
            "
              >
                Most popular
              </p>
            </div>
            <p className="mt-4 text-sm leading-6 text-secondaryDark dark:text-gray-400">
              A plan for all my fellow writers.
            </p>
            <p className="mt-6 flex items-baseline gap-x-1 mb-4">
              <span className="text-4xl font-bold tracking-tight text-secondaryDark dark:text-gray-400 ">
                $0
              </span>
              <span className="text-sm font-semibold leading-6 text-secondaryDark dark:text-gray-400 0">
                / lifetime
              </span>
            </p>
            <Button small onClick={() => router.push("/auth/signin")}>
              Join now
            </Button>
            <ul
              role="list"
              className="mt-8 space-y-3 text-sm leading-6 text-secondaryDark dark:text-gray-400  xl:mt-10"
            >
              {BENEFITS.map((benefit) => (
                <li key={benefit} className="flex gap-x-3">
                  <svg
                    className="h-6 w-5 flex-none text-secondary dark:text-gray-200"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* FAQ */}
        <div>
          <h2 className="text-secondaryDark py-12 mt-12   dark:text-gray-200 text-4xl font-bold text-center">
            FAQ 🤔
            <br />
            <span className="text-sm">{`If you don't find an answer just drop me a message!`}</span>
          </h2>
          <ul>
            {FAQS.map((faq, index) => (
              // use dialog
              <li key={index} className="mt-4 list-none">
                <h3 className="text-lg font-semibold text-secondaryDark dark:text-gray-200">
                  {faq.question}
                </h3>
                <p className="mt-2 text-secondaryDark dark:text-gray-400">
                  {faq.answer}
                </p>
              </li>
            ))}
          </ul>
        </div>

        <footer>
          <div>
            <p className="text-center font-bold text-secondaryDark dark:text-gray-200">
              Made with ❤️ by <a href="https://vangucht.dev">Dovy</a>
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}
